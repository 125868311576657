var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.empty)?_c('empty-list'):_c('section',_vm._l((_vm.items),function(benefit,index){return _c('b-card',{key:index,staticClass:"mb-3 px-md-0"},[_c('b-form-checkbox',{staticClass:"pl-0",on:{"change":function($event){return _vm.handleChangeBenefit(benefit.has_benefit, benefit, index)}},model:{value:(benefit.has_benefit),callback:function ($$v) {_vm.$set(benefit, "has_benefit", $$v)},expression:"benefit.has_benefit"}},[_vm._v(" Fornece "),_c('strong',[_vm._v(" "+_vm._s(benefit.name))]),_vm._v(" aos colaboradores? ")]),_c('b-collapse',{key:`sub-menu-${index}-${index}`,staticClass:"mt-2",attrs:{"visible":benefit.has_benefit,"id":`template-${index}`}},[_c('b-card',{staticClass:"mt-2"},[_c('div',{staticClass:"d-md-flex"},[_c('div',{staticClass:"col-md-6 pl-0"},[_c('div',[_c('span',{staticClass:"d-block mb-2"},[_vm._v(" O "),_c('strong',[_vm._v(" "+_vm._s(benefit.name))]),_vm._v(" é pago em :")]),_c('b-form-radio-group',{staticClass:"d-flex gap-2",attrs:{"id":`payment-${index}`,"name":`payment-${index}`},model:{value:(benefit.payment),callback:function ($$v) {_vm.$set(benefit, "payment", $$v)},expression:"benefit.payment"}},_vm._l((_vm.options),function(option){return _c('b-form-radio',{key:option.value,staticClass:"pl-0",attrs:{"value":option.value},on:{"change":function($event){return _vm.handleChangeBenefit(
                        { payment: option.value },
                        benefit,
                        index
                      )}}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1),(benefit.payment === 1)?_c('div',{staticClass:"mt-3"},[_c('b-form-group',{attrs:{"label":"CNPJ da empresa fornecedora"}},[_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"placeholder":"00.000.000/0000-00"},on:{"change":function($event){return _vm.handleGetCnpj(benefit.enterprise.cnpj, benefit, index)}},model:{value:(benefit.enterprise.cnpj),callback:function ($$v) {_vm.$set(benefit.enterprise, "cnpj", $$v)},expression:"benefit.enterprise.cnpj"}})],1),_c('b-form-group',{attrs:{"label":"E-mail do representante do Benefício"}},[_c('b-input',{attrs:{"placeholder":"email@email.com"},on:{"change":function($event){return _vm.handleChangeBenefit(
                        { contact: benefit.enterprise.contact },
                        benefit,
                        index
                      )}},model:{value:(benefit.enterprise.contact),callback:function ($$v) {_vm.$set(benefit.enterprise, "contact", $$v)},expression:"benefit.enterprise.contact"}})],1)],1):_vm._e()]),(benefit.payment === 1)?_c('b-card',{staticClass:"col-md-6 pr-0 py-2",attrs:{"border-variant":"secondary","no-body":""}},[_c('strong',{staticClass:"d-block mb-2"},[_vm._v(" "+_vm._s(benefit.enterprise.name || "Nome da empresa fornecedora")+" ")]),(benefit.enterprise.principal_activity)?_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(benefit.enterprise.principal_activity)+" ")]):_vm._e(),_c('hr'),_c('span',{staticClass:"d-block"},[_vm._v("CNPJ: "+_vm._s(benefit.enterprise.cnpj || "00.000.000/0000-00"))]),_c('span',{staticClass:"d-block"},[_vm._v("Tipo: "+_vm._s(benefit.enterprise.type || ""))])]):_vm._e()],1)])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }