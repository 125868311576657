<template>
  <div>
    <empty-list v-if="empty"></empty-list>
    <section v-else>
      <b-card
        class="mb-3 px-md-0"
        v-for="(benefit, index) in items"
        :key="index"
      >
        <b-form-checkbox
          class="pl-0"
          v-model="benefit.has_benefit"
          @change="handleChangeBenefit(benefit.has_benefit, benefit, index)"
        >
          Fornece <strong> {{ benefit.name }}</strong> aos colaboradores?
        </b-form-checkbox>

        <b-collapse
          :visible="benefit.has_benefit"
          :id="`template-${index}`"
          class="mt-2"
          :key="`sub-menu-${index}-${index}`"
        >
          <b-card class="mt-2">
            <div class="d-md-flex">
              <div class="col-md-6 pl-0">
                <div>
                  <span class="d-block mb-2">
                    O <strong> {{ benefit.name }}</strong> é pago em :</span
                  >
                  <b-form-radio-group
                    :id="`payment-${index}`"
                    v-model="benefit.payment"
                    :name="`payment-${index}`"
                    class="d-flex gap-2"
                  >
                    <b-form-radio
                      v-for="option in options"
                      :key="option.value"
                      :value="option.value"
                      class="pl-0"
                      @change="
                        handleChangeBenefit(
                          { payment: option.value },
                          benefit,
                          index
                        )
                      "
                    >
                      {{ option.name }}
                    </b-form-radio>
                  </b-form-radio-group>
                </div>
                <div v-if="benefit.payment === 1" class="mt-3">
                  <b-form-group label="CNPJ da empresa fornecedora">
                    <b-input
                      v-mask="'##.###.###/####-##'"
                      placeholder="00.000.000/0000-00"
                      v-model="benefit.enterprise.cnpj"
                      @change="
                        handleGetCnpj(benefit.enterprise.cnpj, benefit, index)
                      "
                    />
                  </b-form-group>
                  <b-form-group label="E-mail do representante do Benefício">
                    <b-input
                      placeholder="email@email.com"
                      v-model="benefit.enterprise.contact"
                      @change="
                        handleChangeBenefit(
                          { contact: benefit.enterprise.contact },
                          benefit,
                          index
                        )
                      "
                    />
                  </b-form-group>
                </div>
              </div>
              <b-card
                v-if="benefit.payment === 1"
                border-variant="secondary"
                no-body
                class="col-md-6 pr-0 py-2"
              >
                <strong class="d-block mb-2">
                  {{ benefit.enterprise.name || "Nome da empresa fornecedora" }}
                </strong>
                <span
                  class="d-block"
                  v-if="benefit.enterprise.principal_activity"
                >
                  {{ benefit.enterprise.principal_activity }}
                </span>
                <hr />
                <span class="d-block"
                  >CNPJ:
                  {{ benefit.enterprise.cnpj || "00.000.000/0000-00" }}</span
                >
                <span class="d-block"
                  >Tipo: {{ benefit.enterprise.type || "" }}</span
                >
              </b-card>
            </div>
          </b-card>
        </b-collapse>
      </b-card>
    </section>
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import { getCnpj } from "@/utils/validations/getCnpj";
import { mapActions } from "vuex";

export default {
  mixins: [listPaginated],

  data() {
    return {
      menus: [],
      params: {},
      empty: false,
      action_name: "benefits",
      selectedBenefit: {},
      options: [
        { value: 1, name: "Cartão" },
        { value: 2, name: "Dinheiro" },
      ],
    };
  },

  methods: {
    ...mapActions(["edit_benefit"]),

    async handleGetCnpj(cnpj, benefit, index) {
      const response = await getCnpj(cnpj);

      this.items[index].enterprise.name = response?.fantasia;
      this.items[index].enterprise.type = response?.tipo;
      this.items[index].enterprise.contact = response?.email;
      this.items[index].enterprise.principal_activity =
        response?.atividade_principal && response?.atividade_principal[0]?.text;

      if (benefit.enterprise.contact && benefit.enterprise.cnpj) {
        this.edit_benefit({
          ...benefit,
          contact: benefit.enterprise.contact,
          enterprise: benefit.enterprise.cnpj?.replace(/[^\d]/g, ""),
        });
      }
    },
    handleChangeBenefit(changed, benefit, index) {
      const PAYMENT_CARD = 1;
      const PAYMENT_CACHE = 2;

      this.selectedBenefit = {
        ...benefit,
        ...changed,
      };

      this.items[index].has_benefit = this.selectedBenefit.has_benefit;

      this.items[index].payment = this.selectedBenefit.payment;

      let { enterprise } = benefit;

      let { contact, cnpj } = enterprise;

      const hasCNPJAdContact = contact && cnpj;

      let payload = {};

      if (benefit.payment === PAYMENT_CACHE) {
        benefit.enterprise = {};

        payload = {
          ...benefit,
          contact: null,
          enterprise: null,
        };
        this.edit_benefit(payload);
      }

      if (benefit.payment === PAYMENT_CARD && hasCNPJAdContact) {
        payload = {
          ...benefit,
          contact,
          enterprise: cnpj?.replace(/[^\d]/g, ""),
        };
        this.edit_benefit(payload);
      }
    },
  },
  created() {
    this.params.profile_id = 4;
  },
  watch: {
    items: {
      handler(newitems, oldItems) {
        const srtNewitems = JSON.stringify(newitems);
        const srtOldItems = JSON.stringify(oldItems);
        if (srtNewitems !== srtOldItems) {
          this.items = newitems?.map((item) => {
            if (!item.enterprise?.name) {
              item.enterprise = {
                cnpj: null,
                name: null,
                principal_activity: null,
                type: null,
                contact: null,
              };
            }
            return item;
          });
          this.empty = this.items?.length === 0 || this.items === undefined;
        }
      },
      deep: true,
    },
  },
};
</script>
